// 수입 담당자 리스트
export const managerList = {
  // 수입 담당자 리스트 정보
  importManagerList: [
    { ctrCd: 'KR', portCd: 'ALL', team: 'Inbound Team', tel: '82-51-797-7400 (line 5)', email: 'docid@ekmtc.com' },
    { ctrCd: 'JP', portCd: 'TYO', team: 'Customer Service Team', tel: '81-3-3500-5051', email: 'tyocs@ekmtc.com' },
    { ctrCd: 'JP', portCd: 'OSA', team: 'Customer Service Team', tel: '81-6-6243-1661', email: 'osacs@ekmtc.com' },
    { ctrCd: 'JP', portCd: 'AXT', team: 'AKITA KAIRIKU UNSO CO., LTD.', tel: '81-18-880-6770', email: 'axt.ope@kairiku.co.jp' },
    { ctrCd: 'JP', portCd: 'CHB', team: 'NIPPON EXPRESS CO.,LTD. CHIBA SHIPPING BRANCH (CHB)', tel: '81-43-238-7311', email: 'nittsu-nexchb@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'FKY', team: 'BINGO EXPRESS CO.,LTD.FUKUYAMA BRANCH (FKY)', tel: '81-84-981-0205', email: 'marine_sect@bintsu.co.jp' },
    { ctrCd: 'JP', portCd: 'HHE', team: 'NARASAKI STAX CO.,LTD. HACHINOHE BRANCH', tel: '81-178-21-6000', email: 'hachi@narasaki-stax.co.jp' },
    { ctrCd: 'JP', portCd: 'HIJ', team: 'HIROKURA CO.,LTD. (HIJ)', tel: '81-82-253-2111', email: 'agent@hirokura.co.jp' },
    { ctrCd: 'JP', portCd: 'HKT', team: 'NIPPON EXPRESS CO.,LTD. FUKUOKA SHIPPING BRANCH (HKT)', tel: '81-92-663-3511', email: 'nittsu-hkt-agent@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'HSM', team: 'NIPPON EXPRESS CO.,LTD. NOBEOKA BRANCH /INTERNATIONAL TRANSPORT DEPT.', tel: '81-982-56-1612', email: 'nittsu-hsm_agent@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'HTA', team: 'Inbound Documentation Team', tel: '029-265-5539', email: 'sales@hitachi-futo.co.jp' },
    { ctrCd: 'JP', portCd: 'IMB', team: 'NIPPON EXPRESS CO., LTD. IMABARI BRANCH.(IMB)', tel: '81-898-48-6900', email: 'nittsu-imabari@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'IMI', team: 'NAGAI CO.,LTD. & IMARI HARBOUR TRANSPORT COMPANY (IMI)', tel: '81-955-20-7110', email: 'imari-agsec@nagaij.co.jp' },
    { ctrCd: 'JP', portCd: 'ISI', team: 'NARASAKI STAX CO., LTD. ISHIKARI OFFICE (ISI)', tel: '81-134-61-6151', email: 'ishiagency@narasaki-stax.co.jp' },
    { ctrCd: 'JP', portCd: 'IWK', team: 'Inbound Documentation Team', tel: '81-827-52-4311', email: 'nittsu-otake_iwakuni_agent@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'IYM', team: 'DAIO KAIUN CO.,LTD. / IYOMISHIMA AGENT', tel: '81-896-24-9220', email: 'agency@daio-kaiun.com' },
    { ctrCd: 'JP', portCd: 'KIJ', team: 'NIPPON EXPRESS CO.,LTD.NIIGATA SHIPPING BRANCH (KIJ)', tel: '81-25-256-1177', email: 'nittsu-niigata-150065@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'KMJ', team: 'MISUMI KAIUN CO., LTD (KMJ)', tel: '81-96-223-5497', email: 't-fujita@misumikaiun.co.jp' },
    { ctrCd: 'JP', portCd: 'KNZ', team: 'KANAZAWA KOUN CO.,LTD. (KNZ)', tel: '81-76-256-0543', email: 'doc_imp@kounknz.co.jp' },
    { ctrCd: 'JP', portCd: 'MAI', team: 'IINO KOUN CO.,LTD. (MAI)', tel: '81-773-75-5371', email: 'mrt@iinokoun.com' },
    { ctrCd: 'JP', portCd: 'MIZ', team: 'NAKATANI KOUN CO.,LTD. (MIZ)', tel: '81-86-523-5551', email: 'ship-agent@nakatani.co.jp' },
    { ctrCd: 'JP', portCd: 'MOJ', team: 'SANKYU INC. MOJI BRANCH (MOJ)', tel: '81-93-321-3999', email: 'mojiagent@sankyu.co.jp' },
    { ctrCd: 'JP', portCd: 'MUR', team: 'NARASAKI STAX CO.,LTD. MURORAN BRANCH (MUR)', tel: '81-143-24-3101', email: 'y.ota@narasaki-stax.co.jp' },
    { ctrCd: 'JP', portCd: 'MYJ', team: 'NIPPON EXPRESS CO., LTD. MATSUYAMA OFFICE (MYJ)', tel: '81-89-952-2341', email: 'aya.ikuta@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'NAO', team: 'NIPPON EXPRESS CO.,LTD.NAOETSU MARINE OFFICE (NAO)', tel: '81-25-544-5765', email: 'nittsu-naoetsu_150055@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'NGO', team: 'GOYO KAIUN KAISHA., LTD. (NGO)', tel: '81-52-652-2181', email: 'kamotsu@gkk.co.jp' },
    { ctrCd: 'JP', portCd: 'NGS', team: 'NIPPON EXPRESS CO., LTD NAGASAKI BRANCH (NGS)', tel: '81-95-895-7651', email: 'n12kaiun@crux.ocn.ne.jp' },
    { ctrCd: 'JP', portCd: 'OIT', team: 'ONAHAMA KAIRIKU UNSO KAISHA. LTD.', tel: '81-97-524-2118', email: 'nittsu-oita_agent@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'ONA', team: 'NIPPON EXPRESS CO.,LTD. OSAKA INT "L TRANSPORT BRANCH (OSA)', tel: '81-246-75-0210', email: 'agent@iocs.co.jp' },
    { ctrCd: 'JP', portCd: 'OSA', team: 'Inbound Documentation Team', tel: '81-6-4395-3831', email: 'nittsu-koun@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'SBS', team: 'NIPPON EXPRESS CO.,LTD. SHIBUSHI BRANCH /MARINE DEPT.', tel: '81-099-472-1121', email: 'nittsu-sbssp@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'SEN', team: 'Inbound Documentation Team', tel: '81-996-26-3335', email: 'eigyou-g@bussan.co.jp' },
    { ctrCd: 'JP', portCd: 'SDJ', team: 'SHIOGAMA　KOUN CO.,LTD. (SDJ)', tel: '81-22-254-0948', email: 'kmtu@shiogama-koun.co.jp' },
    { ctrCd: 'JP', portCd: 'SKT', team: 'NIPPON EXPRESS CO.,LTD. SAKATA BRANCH. (SKT)', tel: '81-234-35-1288', email: 'shin.sato@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'SMN', team: 'SAKAIMINATO KAIRIKU UNSO.,LTD. (SMN)', tel: '81-859-42-2124', email: 'agt-1@sakai-kairiku.co.jp' },
    { ctrCd: 'JP', portCd: 'SMZ', team: 'HAKUEI TRANS CO.,LTD. (SMZ)', tel: '81-54-352-1785', email: 'hakueiag@pdcc.co.jp' },
    { ctrCd: 'JP', portCd: 'TAK', team: 'TAKAMATSU-SHOUN CORPORATION (TAK)', tel: '81-87-822-5184', email: 'agency@takshoun.co.jp' },
    { ctrCd: 'JP', portCd: 'THS', team: 'AICHIKAIUN SANGYO CO.,LTD. (THS)', tel: '81-532-32-1048', email: 'container@aichikaiunsangyo.co.jp' },
    { ctrCd: 'JP', portCd: 'TKS', team: 'KYODO KOUN & CO.,LTD. (TKS)', tel: '81-885-35-7010', email: 'kyodotks@mc.pikara.ne.jp' },
    { ctrCd: 'JP', portCd: 'TKY', team: 'NIPPON EXPRESS CO.,LTD. TOKUYAMA SHIPPING BRANCH (TKY)', tel: '81-834-27-0202', email: 'nittsu-shunan_agency@nipponexpress.com' },
    { ctrCd: 'JP', portCd: 'TMK', team: 'NARASAKI STAX CO.,LTD. (TMK)', tel: '81-145-26-8511', email: 'nscy@narasaki-stax.co.jp' },
    { ctrCd: 'JP', portCd: 'TOS', team: 'NIPPON EXPRESS CO.,LTD. TOYAMASHINKO INT L TRANSPORT BRANCH (TOS)', tel: '81-766-83-7325', email: 'pa-jptos@ekmtc.com' },
    { ctrCd: 'JP', portCd: 'TRG', team: 'Inbound Documentation Team', tel: '81-770-24-5522', email: 'contena@tsurugakairiku.co.jp' },
    { ctrCd: 'JP', portCd: 'YAT', team: 'NIPPON EXPRESS CO.,LTD. YATSUSHIRO BRANCH', tel: '81-965-37-0011', email: 'pa-jpyat@ekmtc.com' },
    { ctrCd: 'JP', portCd: 'YKK', team: 'JAPAN TRANSCITY CORPORATION (YKK)', tel: '81-59-361-7702', email: 'contimp@trancy.co.jp' },
    { ctrCd: 'JP', portCd: 'UKB', team: 'NIPPON EXPRESS CO.,LTD.KOBE INTERNATIONAL TRANSPORT BRANCH (UKB)', tel: '81-78-230-6107', email: 'nittsu-koun@nipponexpress.com' },
    { ctrCd: 'CN', portCd: 'SHA', team: 'Inbound Documentation Team', tel: '86-21-63091692', email: 'shaid@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'NKG', team: 'Customer Service Team', tel: '86-25-58809181', email: 'shabk@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'CKG', team: 'Sales Team', tel: '86-23-63710439', email: 'shasa@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'XGG', team: 'Customer Service Team', tel: '86-22-23303484*8009', email: 'xggcs@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'DLC', team: 'Customer Service Team', tel: '86-411-82580987', email: 'dlccs@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'CKG', team: 'Sales Team', tel: '86-23-63710439', email: 'shasa@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'TAO', team: 'Inbound Documentation Team', tel: '86-532-85793722', email: 'taoid@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'LYG', team: 'Customer Service Team', tel: '86-518-82233750', email: 'lyg@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'NBO', team: 'Customer Service Team', tel: '86-574-87093197', email: 'nbocs@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'SZP', team: 'Inbound Documentation Team', tel: '86-755-82562507', email: 'szpid@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'CAN', team: 'Customer Service Team', tel: '86-20-38262441-316', email: 'caned@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'XMN', team: 'Customer Service Team', tel: '(86) 592-2685822', email: 'xmncs@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'ZSN', team: 'Customer Service Team ', tel: '86-760-88239776', email: 'zsncs@ekmtc.com' },
    { ctrCd: 'CN', portCd: 'FOC', team: 'Customer Service Team', tel: '86-591-88522335', email: 'liner@penavicofz.com.cn' },
    { ctrCd: 'CN', portCd: 'SWA', team: 'Customer Service Team', tel: '86-754-88737623', email: 'kmtcteam@penavicost.com' },
    { ctrCd: 'TW', portCd: 'TXG', team: 'Ms. Esther Chen', tel: '886-4-2299-38286607', email: 'estherchen6041@tvstch.tvlgroup.com.tw' },
    { ctrCd: 'TW', portCd: 'KHH', team: 'Ms. Grace Dai', tel: '886-7536-06127607', email: 'gracetai@tvskao.tvlgroup.com.tw' },
    { ctrCd: 'HK', portCd: 'HKG', team: 'Inbound Documentation Team', tel: '(852) 2851-9638', email: 'hkgid@ekmtc.com' },
    { ctrCd: 'PH', portCd: 'ALL', team: 'Inbound Documentation Team', tel: '(632) 8354-0940 to 49(632) 8353-1904', email: 'mnlid@ekmtc.com' },
    { ctrCd: 'SG', portCd: 'ALL', team: 'Inbound Documentation Team', tel: '62208558 ext 124', email: 'sinid@ekmtc.com' },
    { ctrCd: 'MY', portCd: 'PKG', team: 'Inbound Documentation Team', tel: '603 - 3342 2287 Ext. 1700', email: 'pkgid@ekmtc.com' },
    { ctrCd: 'MY', portCd: 'PGU', team: 'Inbound Documentation Team', tel: '607 - 3511 741', email: 'pguid@ekmtc.com' },
    { ctrCd: 'MY', portCd: 'PEN', team: 'Inbound Documentation Team', tel: '604-250 3228 Ext. 813', email: 'penid@ekmtc.com' },
    { ctrCd: 'ID', portCd: 'JKT', team: 'Import Documentation Team', tel: '62-21-3950 4900 Ext.175', email: 'jktcid@ekmtc.com' },
    { ctrCd: 'ID', portCd: 'SUB', team: 'Import Documentation Team', tel: '62-31-5347899 Ext.8073', email: 'imp.sub@ekmtc.com' },
    { ctrCd: 'ID', portCd: 'BLW', team: 'Ms. Ayu Kartika Sari', tel: '62-61 - 6940044 Ext: 6033', email: 'ayu.sari@samudera.id' },
    { ctrCd: 'ID', portCd: 'SRG', team: 'Ms. Nirwulan Daru', tel: '62-24-3544929 ext 6074', email: 'nirwulan.daru@samudera.id' },
    { ctrCd: 'ID', portCd: 'BDG', team: 'Mr. Jati Priyoko', tel: '62-22-7507210 Ext. 4461', email: 'jati.priyoko@samudera.id' },
    { ctrCd: 'ID', portCd: 'PNK', team: 'Mr. Suwarsono', tel: '62-561-761801 Ext.6012', email: 'suwarsono@samudera.id' },
    { ctrCd: 'ID', portCd: 'PLM', team: 'Mr. Sapta Wiguna', tel: '62-711-313616 Ext. 6060', email: 'sapta.wiguna@samudera.id' },
    { ctrCd: 'ID', portCd: 'UPG', team: 'Ms. Utami Warastiti ', tel: '62411854844 ext 6031', email: 'utami.soebagyo@samudera.id' },
    { ctrCd: 'ID', portCd: 'PJG', team: 'Ms. Rizka Dianita', tel: '0721 265700 ext 6032', email: 'rizka.dianita@samudera.id' },
    { ctrCd: 'ID', portCd: 'BDJ', team: 'Ms. Novie Aulia', tel: '62511-4366014, EXT : 6032', email: 'novie.aulia@samudera.id' },
    { ctrCd: 'ID', portCd: 'BAN', team: 'Ms. Yeti Triyana', tel: '62-542-732673 Ext.117', email: 'yeti.triyana@samudera.id' },
    { ctrCd: 'ID', portCd: 'BTM', team: 'Mr. Johan Meiransyah', tel: '62-778 457745 ext 6051', email: 'johan.meiransyah@samudera.id' },
    { ctrCd: 'ID', portCd: 'SRI', team: 'Mr. Haris Hervian', tel: '62-541-732964,65,66', email: 'haris.hervian@samudera.id' },
    { ctrCd: 'ID', portCd: 'DJB', team: 'MR. Aprisarado Taurussino', tel: '62-741-34304', email: 'apri.taurussino@samudera.id' },
    { ctrCd: 'ID', portCd: 'PDG', team: 'Ms. Asih Yuhesty', tel: '62-751-893466', email: 'asih.yuhesty@samudera.id' },
    { ctrCd: 'TH', portCd: 'ALL', team: 'Inbound Team', tel: '662-120-9557/9561', email: 'bkkid@ekmtc.com' },
    { ctrCd: 'VN', portCd: 'SGN', team: 'Inbound Documentation Team', tel: '84-28-38246116 Ext.121~125', email: 'sgnid@ekmtc.com' },
    { ctrCd: 'VN', portCd: 'HPH', team: 'Inbound Documentation Team', tel: '84-225-3686808 Ext.102, 104, 116', email: 'hphid@ekmtc.com' },
    { ctrCd: 'VN', portCd: 'HAN', team: 'Inbound Documentation Team', tel: '84-24-35746732', email: 'hphed@ekmtc.com' },
    { ctrCd: 'BD', portCd: 'CGP', team: 'Inbound Documentation Team', tel: '0088 02333313140-2', email: 'morshed@ctg.qc-group.com' },
    { ctrCd: 'IN', portCd: 'BOM', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'AGB', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'CCW', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'DIG', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'AWM', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'UME', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'BHC', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'NKI', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'NSA', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MUU', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'TLG', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'IXU', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'SAC', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'VVD', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'IWL', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'RAJ', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KRM', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'SCH', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MPT', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'LPJ', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MLR', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PPR', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'WHD', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'TRP', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'AIR', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'AWW', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MEP', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PRM', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'NOL', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MML', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'BCL', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'JNP', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'GAN', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KVA', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KSU', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'BOK', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'BSL', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'HYD', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'SPZ', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'BGQ', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'ZIP', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'DNN', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MWC', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MHL', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'HDB', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PEP', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'NOI', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PNB', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'CKP', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'SPP', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MDD', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'HBL', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'EKI', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'GIE', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PNL', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'THI', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PLT', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'JSZ', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'STR', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'APS', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'SAP', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PTM', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'NGA', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'AFK', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'GPE', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'API', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'TLN', team: 'Inbound Documentation Team', tel: '91-22-49222526', email: 'mumid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'NDE', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'STT', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KTW', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'JHT', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'BWL', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'DRI', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'TKD', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PPG', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'FBD', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KPR', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'LON', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'GHR', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MOB', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PTI', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'REW', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'AGR', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'BDM', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'ACC', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PYL', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PGH', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KSH', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'ATL', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PWL', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KKU', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'JDR', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MPR', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KOT', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'ROR', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'ICD', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'DEL', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'THJ', team: 'Inbound Documentation Team', tel: '91-11- 431 21 712', email: 'deldoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MUN', team: 'Inbound Documentation Team', tel: '91 2836 297013', email: 'gdmdoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'AMD', team: 'Inbound Documentation Team', tel: ' + 9179 4896 7003', email: 'amd@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'JGA', team: 'Inbound Documentation Team', tel: '91 2836 297013', email: 'gdmdoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'BRC', team: 'Inbound Documentation Team', tel: '91 2836 297013', email: 'gdmdoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'ANK', team: 'Inbound Documentation Team', tel: '91 2836 297013', email: 'gdmdoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'SAU', team: 'Inbound Documentation Team', tel: '91 2836 297013', email: 'gdmdoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'JKA', team: 'Inbound Documentation Team', tel: '91 2836 297013', email: 'gdmdoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KDL', team: 'Inbound Documentation Team', tel: '91 2836 297013', email: 'gdmdoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'GNG', team: 'Inbound Documentation Team', tel: '91 2836 297013', email: 'gdmdoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MDS', team: 'Inbound Documentation Team', tel: '91 2836 297013', email: 'gdmdoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KLS', team: 'Inbound Documentation Team', tel: '91 2836 297013', email: 'gdmdoc@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PAV', team: 'Inbound Documentation Team', tel: '+ 91 261 4888 308', email: 'sur@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'HZR', team: 'Inbound Documentation Team', tel: '+ 91 261 4888 308', email: 'sur@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'DPR', team: 'Inbound Documentation Team', tel: ' + 91 161 4084821', email: 'lud@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'LDH', team: 'Inbound Documentation Team', tel: ' + 91 161 4084821', email: 'lud@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'QRP', team: 'Inbound Documentation Team', tel: ' + 91 161 4084821', email: 'lud@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'SWL', team: 'Inbound Documentation Team', tel: ' + 91 161 4084821', email: 'lud@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'OWP', team: 'Inbound Documentation Team', tel: ' + 91 161 4084821', email: 'lud@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'CWP', team: 'Inbound Documentation Team', tel: ' + 91 161 4084821', email: 'lud@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KCM', team: 'Inbound Documentation Team', tel: ' + 91 161 4084821', email: 'lud@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KNL', team: 'Inbound Documentation Team', tel: ' + 91 161 4084821', email: 'lud@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'PSW', team: 'Inbound Documentation Team', tel: ' + 91 161 4084821', email: 'lud@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'APA', team: 'Inbound Documentation Team', tel: '91 44 66067709', email: 'maaid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'NDR', team: 'Inbound Documentation Team', tel: '91 44 66067709', email: 'maaid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'VSA', team: 'Inbound Documentation Team', tel: '91 44 66067709', email: 'maaid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'KTP', team: 'Inbound Documentation Team', tel: '91 44 66067709', email: 'maaid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'BLR', team: 'Inbound Documentation Team', tel: '91 44 66067709', email: 'maaid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'MAA', team: 'Inbound Documentation Team', tel: '91 44 66067709', email: 'maaid@ekmtc.com' },
    { ctrCd: 'IN', portCd: 'VZG', team: 'Inbound Documentation Team', tel: '91-33-4012 8530', email: 'ssacal.impdoc@seahorsegroup.co.in' },
    { ctrCd: 'IN', portCd: 'VTZ', team: 'Inbound Documentation Team', tel: '91-33-4012 8530', email: 'ssacal.impdoc@seahorsegroup.co.in' },
    { ctrCd: 'IN', portCd: 'DUR', team: 'Inbound Documentation Team', tel: '91-33-4012 8530', email: 'ssacal.impdoc@seahorsegroup.co.in' },
    { ctrCd: 'IN', portCd: 'MPL', team: 'Inbound Documentation Team', tel: '91-33-4012 8530', email: 'ssacal.impdoc@seahorsegroup.co.in' },
    { ctrCd: 'IN', portCd: 'CCU', team: 'Inbound Documentation Team', tel: '91-33-4012 8530', email: 'ssacal.impdoc@seahorsegroup.co.in' },
    { ctrCd: 'IN', portCd: 'HAL', team: 'Inbound Documentation Team', tel: '91-33-4012 8530', email: 'ssacal.impdoc@seahorsegroup.co.in' },
    { ctrCd: 'IN', portCd: 'BGJ', team: 'Inbound Documentation Team', tel: '91-33-4012 8530', email: 'ssacal.impdoc@seahorsegroup.co.in' },
    { ctrCd: 'IN', portCd: 'TUT', team: 'Inbound Documentation Team', tel: '0461- 2352921', email: 'ssatut@seahorsegroup.co.in' },
    { ctrCd: 'IN', portCd: 'COK', team: 'Inbound Documentation Team', tel: '91-484-4077 907', email: 'ssacch@seahorsegroup.co.in' },
    { ctrCd: 'KH', portCd: 'ALL', team: 'Chanvatta Sam', tel: '(855)23 975914', email: 'chanvatta.sam@meridian-asia.com' },
    { ctrCd: 'RU', portCd: 'ALL', team: 'Office', tel: '7-423-2215700', email: 'mail@belsu.net' },
    { ctrCd: 'AE', portCd: 'ALL', team: 'Inbound Documentation Team', tel: '04-3594888 extn 2551', email: 'docs.1@modern-shipping.com' },
    { ctrCd: 'IR', portCd: 'THR', team: 'Mr.Sepehr Mesbah', tel: '0098-21-83560000', email: 'Sepehr@Jonoubdarya.com' },
    { ctrCd: 'IR', portCd: 'BND', team: 'Ms.Rima Bahrani', tel: '0098-76-32242313', email: 'BS-imp4@Jonoubdarya.com' },
    { ctrCd: 'IR', portCd: 'BUZ', team: 'Mr.Nader Khateri', tel: '', email: 'Nader@Jonoubdarya.com' },
    { ctrCd: 'MM', portCd: 'ALL', team: 'Customer Service Team', tel: '951 8379981~83 Ext.150', email: 'pyaephyo.aung@meridian-asia.com' },
    { ctrCd: 'SA', portCd: 'ALL', team: 'Inbound Documentation Team', tel: '00966–13–8324133 Ext.301', email: 'impdoc@haaco.com' },
    { ctrCd: 'OM', portCd: 'ALL', team: 'Inbound Documentation Team', tel: '968 24790992', email: 'imports.kmtc@sealandoman.com' },
    { ctrCd: 'BH', portCd: 'ALL', team: 'Customer Service', tel: '97317515074', email: 'import.csu1@shippingbahrain.com' },
    { ctrCd: 'KE', portCd: 'ALL', team: 'Inbound Documentation Team', tel: '+254-702018854', email: 'docs1@rsske.com' },
    { ctrCd: 'PK', portCd: 'ALL', team: 'Inbound Documentation Team', tel: 'UN: 0092-21-111-111-862 Direct: 0092-21-35825799', email: 'csimp-kmtc.pk@umapk.com' },
    { ctrCd: 'TW', portCd: 'KEL', team: 'Ms. Jane Lin', tel: '886-2-271337883755', email: 'janelin@tvs.tvlgroup.com.tw' },
    { ctrCd: 'TW', portCd: 'KEL', team: 'Ms. April Yang', tel: '886-2-271337883756', email: 'aprilyang@tvs.tvlgroup.com.tw' },
    { ctrCd: 'TW', portCd: 'KEL', team: 'Ms. Winni Chang ', tel: '886-2-271337883754', email: 'winnichang@tvs.tvlgroup.com.tw' },
    { ctrCd: 'TW', portCd: 'KEL', team: 'Ms. Alice Pan', tel: '886-2-271337883757', email: 'alicepan@tvs.tvlgroup.com.tw' }
  ]
}
