var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "70%" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("msg.CMBK100_M1.038")) + " ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX010P030.001"))),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX010P030.002"))),
          ]),
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.011")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$ekmtcCommon.changeDatePattern(
                        _vm.vesselInfoDetail.bkgDocCls,
                        "."
                      )
                    )
                  ),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.012")))]),
                _c("td", [
                  _vm._v(_vm._s(_vm.vesselInfoDetail.cllPic) + " "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.vesselInfoDetail.cllPic &&
                            _vm.memberDetail.userCtrCd === "KR" &&
                            _vm.parentInfo.polPortCd !== "DSN" &&
                            _vm.parentInfo.polPortCd !== "KPO" &&
                            _vm.parentInfo.polPortCd !== "KAN",
                          expression:
                            "vesselInfoDetail.cllPic && memberDetail.userCtrCd === 'KR' && (parentInfo.polPortCd !== 'DSN' && parentInfo.polPortCd !== 'KPO' && parentInfo.polPortCd !== 'KAN')",
                        },
                      ],
                    },
                    [_vm._v(" DOCCLL@EKMTC.COM ")]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.013")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$ekmtcCommon.changeDatePattern(
                        _vm.vesselInfoDetail.vgmDocCls,
                        "."
                      )
                    )
                  ),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.014")))]),
                _c("td", [
                  _vm._v(_vm._s(_vm.vesselInfoDetail.srPic) + " "),
                  _vm.$ekmtcCommon.isNotEmpty(_vm.vesselInfoDetail.srPic) &&
                  _vm.memberDetail.userCtrCd === "KR"
                    ? _c("span", [_vm._v(" DOCSR@EKMTC.COM ")])
                    : _vm._e(),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.015")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$ekmtcCommon.changeDatePattern(
                        _vm.vesselInfoDetail.bkgMfCls,
                        "."
                      )
                    )
                  ),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.023")))]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.userList.usrNm || "") +
                      " " +
                      _vm._s(_vm.userList.usrPhone || "") +
                      " "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.userList.usrNm &&
                            _vm.memberDetail.userCtrCd === "KR",
                          expression:
                            "userList.usrNm && memberDetail.userCtrCd === 'KR'",
                        },
                      ],
                    },
                    [_vm._v(" DOCCA@EKMTC.COM")]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.017")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$ekmtcCommon.changeDatePattern(
                        _vm.vesselInfoDetail.bkgCgoCls,
                        "."
                      )
                    )
                  ),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.016")))]),
                _c("td", [
                  _vm._v(_vm._s(_vm.vesselInfoDetail.ediPic) + " "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.vesselInfoDetail.ediPic &&
                            _vm.memberDetail.userCtrCd === "KR" &&
                            _vm.parentInfo.polPortCd !== "DSN" &&
                            _vm.parentInfo.polPortCd !== "KPO" &&
                            _vm.parentInfo.polPortCd !== "KAN",
                          expression:
                            "vesselInfoDetail.ediPic && memberDetail.userCtrCd === 'KR' && (parentInfo.polPortCd !== 'DSN' && parentInfo.polPortCd !== 'KPO' && parentInfo.polPortCd !== 'KAN')",
                        },
                      ],
                    },
                    [_vm._v(" DOCEDI@EKMTC.COM ")]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [
                  _vm._v(
                    _vm._s(_vm.$t("msg.ONEX010P030.018")) +
                      " / " +
                      _vm._s(_vm.$t("msg.CSCT060G030.035"))
                  ),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.mrnNo))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.019")))]),
                _c("td", [_vm._v(_vm._s(_vm.vesselInfoDetail.clsgNo))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SCHD010P040.029")))]),
                _c("td", [
                  _vm._v(_vm._s(_vm.vesselInfoDetail.ediPic) + " "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.vesselInfoDetail.ediPic &&
                            _vm.memberDetail.userCtrCd === "KR" &&
                            _vm.parentInfo.polPortCd !== "DSN" &&
                            _vm.parentInfo.polPortCd !== "KPO" &&
                            _vm.parentInfo.polPortCd !== "KAN",
                          expression:
                            "vesselInfoDetail.ediPic && memberDetail.userCtrCd === 'KR' && (parentInfo.polPortCd !== 'DSN' && parentInfo.polPortCd !== 'KPO' && parentInfo.polPortCd !== 'KAN')",
                        },
                      ],
                    },
                    [_vm._v(" DOCEDI@EKMTC.COM ")]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.021")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(_vm._s(_vm.vesselInfoDetail.cfsCls)),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.024")))]),
                _vm.issManagerList.length > 0
                  ? _c("td", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.polCtrCd === "KR",
                              expression: "polCtrCd === 'KR'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.issManagerList[0].blIssNm || "") +
                              " " +
                              _vm._s(_vm.issManagerList[0].blIssTel || "") +
                              " " +
                              _vm._s(_vm.issManagerList[0].blIssAddr || "")
                          ),
                        ]
                      ),
                    ])
                  : _vm.beforeIssueBl !== ""
                  ? _c("td", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.polCtrCd === "KR",
                              expression: "polCtrCd === 'KR'",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.beforeIssueBl || ""))]
                      ),
                    ])
                  : _c("td"),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.020")))]),
                _vm.parentInfo.polShtmCd !== "02"
                  ? _c("td", { attrs: { colspan: "5" } }, [
                      _vm._v(_vm._s(_vm.vesselInfoDetail.cgoPic)),
                    ])
                  : _vm._e(),
                _vm.parentInfo.polShtmCd === "02"
                  ? _c(
                      "td",
                      { attrs: { colspan: "5" } },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.txtCfsPolTrmlInfo))]),
                        _c("font", { attrs: { color: "red", id: "cfsred" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.087"))),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX010P030.003"))),
          ]),
          _c("table", { staticClass: "tbl_col tal" }, [
            _vm._m(1),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.004")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.005")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.006")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.007")))]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _vm.anStatus === "N"
                  ? _c("td", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.008")))])
                  : _vm.anStatus === "Y"
                  ? _c("td", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P030.022")))])
                  : _vm._e(),
                _c("td", [_vm._v(_vm._s(_vm.managerInfo.name))]),
                _c("td", [_vm._v(_vm._s(_vm.managerInfo.telNumber))]),
                _c("td", [_vm._v(_vm._s(_vm.managerInfo.email))]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "130" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "130" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }